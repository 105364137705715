<template>
  <header class="hm1">
    <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
    <nav class="navbar">
      <a class="button" href="/home" id="nav_/home">Home</a>
      <a class="button" href="/about" id="nav_/about">About</a>
      <a class="button" href="/services" id="nav_/services">Services</a>
      <a class="button" href="/contact" id="nav_/contact">Contact</a>
    </nav>
  </header>
  <section class="hero">
    <p class="title">We're building the <i>Future</i> Byte by Byte.</p>
    <p class="subtitle">Delivering comprehensive SaaS products and expert software consulting & development to fuel your growth.</p>
  </section>
  <section class="prodsec1">
    <div class="d1">
      <p class="title">Introducing <span>Zaqnet</span></p>
      <p class="subtitle">Orchestrating a Symphony of Commerce.</p>
      <p class="body">Zaqnet disrupts the traditional commerce landscape with a revolutionary app ecosystem. We cater to every facet of the modern marketplace, offering dedicated applications for manufacturers, retailers, and consumers. Imagine a seamless network – manufacturers optimize production with our tools, retailers leverage data-driven insights to elevate customer experiences, and consumers enjoy a frictionless shopping journey. But Zaqnet doesn't stop there. Businesses gain a powerful cloud suite, consolidating all their operational needs into a single, unified platform.  Say goodbye to the hassle of juggling multiple app and service providers. Zaqnet orchestrates a symphony of commerce, empowering businesses to manage everything with unparalleled efficiency.</p>
      <button class="b1">Learn More</button>
    </div>
    <div class="d2">
      <v-img :src="require('../assets/ai1.png')"/>
    </div>
  </section>
  <section class="prodsec2">
    <p class="title1">We offer a comprehensive suite of services</p>
    <div class="d1">
      <div class="sd1" style="background-color: #D8BFD8;">
        <p class="title2">Custom Software Development</p>
        <p class="body">Our skilled developers craft bespoke solutions tailored to your unique needs and workflows, ensuring a perfect fit for your business.</p>
        <a href="/services"><button class="b1">Learn More</button></a>
      </div>
      <div class="sd1" style="background-color: #DDD06A;">
        <p class="title2">Industry-Leading SaaS Solutions</p>
        <p class="body">Leverage our cutting-edge, pre-built SaaS platforms designed to streamline operations, enhance user experience, and deliver a competitive edge.</p>
        <a href="/services"><button class="b1">Learn More</button></a>
      </div>
      <div class="sd1" style="background-color: #89CFF0;">
        <p class="title2">Expert IT Services</p>
        <p class="body">From insightful consulting and strategic planning to seamless implementation and ongoing support, our experienced team is here to guide you every step of the way.</p>
        <a href="/services"><button class="b1">Learn More</button></a>
      </div>
    </div>
  </section>
  <section class="prodsec3">
    <p class="title1" style="color:grey;">Why Choose <span style="color: black;">RDS Corp</span>?</p>
    <p class="body">At RDS Corp, we recognize the inherent heterogeneity within the business landscape.  This understanding fuels our commitment to delivering a multifaceted approach. We provide a seamlessly integrated app ecosystem specifically designed to connect retailers, manufacturers, and consumers, fostering a unified and efficient commercial environment.  Furthermore, we offer the flexibility of bespoke software development, meticulously crafted to address your unique operational needs.  Alternatively, you can leverage our industry-leading, pre-built SaaS solutions, ensuring a rapid deployment.  Our team of passionate and accomplished IT professionals boasts a demonstrably successful track record across a diverse range of industries.  This depth of experience translates to the development of scalable and future-proof solutions that seamlessly adapt alongside your evolving business, guaranteeing your continued success in a dynamic marketplace.  Choose RDS Corp as your strategic partner and gain access to a comprehensive suite of services designed to cater to your specific requirements and empower your organization to achieve long-term prosperity.</p>
  </section>
  <section class="prodsec3">
    <p class="title1">Benefits for Your Business</p>
    <p class="body">By partnering with RDS Corp, you can expect a one-two punch for your business: enhanced efficiency through streamlined workflows and data-driven decision making, coupled with a boost in customer experience thanks to personalization and improved communication. This translates to reduced costs by eliminating the need for multiple vendors and streamlining your IT infrastructure.</p>
  </section>
  <section class="prodsec4">
    <a href="/contact"><button class="b1">Get a Free Consultation</button></a>
    <a href="/services"><button class="b1">Explore Our SaaS Solutions</button></a>
    <a href="/services"><button class="b1">Learn More About Custom Development Services</button></a>
  </section>
  <footer class="fm1">
    <div class="d1">
      <div class="sd1">
        <v-img class="logo" :src="require('../assets/logo3.png')" />
      </div>
      <div class="sd2">
        <div class="s1">
          <p class="title">General</p>
          <a class="link n" href="about">About Us</a>
          <a class="link n" href="services">Services</a>
          <a class="link n" href="contact">Contact Us</a>
          <p class="title">Products</p>
          <a class="link n" href="services" target="_blank">Zaqnet</a>
        </div>
        <div class="s1">
          <p class="title">Socials</p>
          <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"><span class="mdi mdi-facebook"></span> Facebook</a>
          <a class="link ig" href="https://www.instagram.com/rdscorpglobal/" target="_blank"><span class="mdi mdi-instagram"></span> Instagram</a>
          <a class="link x" href="https://www.x.com/rdscorpglobal/"  target="_blank"><span class="mdi mdi-twitter"></span> Twitter</a>
          <a class="link ln" href="https://www.linkedin.com/company/rdscorp/" target="_blank"><span class="mdi mdi-linkedin"></span> LinkedIn</a>
        </div>
        <div class="s1">
          <p class="title">Terms & Policies</p>
          <a class="link n" href="terms">Terms & Conditions</a>
          <a class="link n" href="privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="d2">
      <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'HelloWorld',
  data: () => ({
  }),
  mounted() {
    document.title = 'RDS Corp'
    if (document.getElementById(`nav_/home`)){
      document.getElementById(`nav_/home`).classList.add('selected')
    }
  }
}
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}
.hm1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg,#fff 90%,transparent 100%);
  padding: 20px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 80px;
    width: 80px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  nav {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    gap: 25px;

    .button {
      background-color: rgb(229,229,229);
      height: 40px;
      width: auto;
      border-radius: 1000px;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      display: grid;
      place-content: center;
    }

    .button:hover {
      outline: 2px solid #046fdb;
      outline-offset: 2px;
    }

    .button.selected {
      background-color: black;
      color: white;
    }
  }
}
.hero{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  padding-top: 225px;
  padding-bottom: 200px;
  .title{
    font-family: fb;
    font-size: 55px;
    margin-top: 150px;
    i{
      background-color: rgb(44, 167, 44);
      color: white;
      padding-left: 1vw;
      padding-right: 1vw;
    }
  }
  .subtitle{
    margin-top: 50px;
    font-family: fm;
    font-size: 35px;
    color: gray;
  }
}
.prodsec1{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  gap: 100px;
  margin-top: 50px;
  align-items: center;
  .d1{
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .title{
      font-family: fbi;
      font-size: 55px;
      span{
        font-family: fb;
        color: red;
      }
    }
    .subtitle{
      font-size: 35px;
      font-family: fm;
      color: gray;
    }
    .body{
      font-size: 20px;
      margin-top: 20px;
    }
    .b1{
      font-size: 20px;
      padding: 10px;
      border-radius: 100px;
      background-color: #0071e3;
      color: white;
      display: block;
      margin: 30px;
      width: 150px;
    }
  }
  .d2{
    height: 400px;
    width: 400px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #fff;
    v-img{
      width: 100%;
      height: 100%;
    }
  }
}
.prodsec2{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 150px;
  .title1{
    font-family: fb;
    font-size: 50px;
  }
  .d1{
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    gap: 30px;
    .sd1{
      height: auto;
      min-height: 450px;
      width: 400px;
      background-color: #0071e3;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title2{
        font-size: 35px;
        font-family: fm;
      }
      .body{
        font-size: 20px;
      }
      .b1{
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        color: white;
        border-radius: 100px;
      }
      .b1:hover{
        background-color: #00000099;
      }
    }
  }
}
.prodsec3{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 150px;
  .title1{
    font-family: fb;
    font-size: 55px;
  }
  .body{
    font-size: 25px;
  }
}
.prodsec4{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
  justify-content: center;
  .b1{
    font-size: 20px;
    margin: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background-color: #0071e3;
    border-radius: 100px;
  }
  .b1:hover{
    opacity: 0.8;
  }
}
.fm1{
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: rgb(0, 0, 0);
  padding-top: 20px;
  .d1{
    display: flex;
    justify-content: space-between;
    .sd1{
      display: flex;
      flex-direction: column;
      .logo{
        width: 250px;
        height: 250px;
      }
    }
    .sd2{
      display: flex;
      padding-top: 30px;
      gap: 50px;
      .s1{
        display: flex;
        flex-direction: column;
        color: #fff;
        gap: 5px;
        .title{
          color: rgb(142, 142, 142);
          font-size: 17.5px;
        }
        .link{
          font-size: 20px;
          cursor: pointer;
        }
        .link.fb:hover{
          color: #1877f2;
        }
        .link.ig:hover{
          color: #fe0b81;
        }
        .link.x:hover{
          color: #1DA1F2;
        }
        .link.ln:hover{
          color: #0077b5;
        }
        .link.n:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .d2{
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    .title{
      width: 100%;
      text-align: center;
      font-family: fl;
      color: white;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait){
  .hm1{
    padding: 30px;
    height: 150px;
    .logo{
      width: 70px;
      height: 70px;
    }
    
    nav{
      display: none;
    }
  }
  .hero{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
    .title{
      font-family: fb;
      font-size: 45px;
      margin-top: 90px;
      i{
        background-color: rgb(44, 167, 44);
        color: white;
        padding-left: 1vw;
        padding-right: 1vw;
      }
    }
    .subtitle{
      margin-top: 30px;
      font-family: fm;
      font-size: 25px;
      color: gray;
    }
  }
  .prodsec1{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
    align-items: center;
    .d1{
      height: auto;
      width: auto;
      flex-shrink: 1;
      flex-grow: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .title{
        font-family: fbi;
        font-size: 35px;
        span{
          font-family: fb;
          color: red;
        }
      }
      .subtitle{
        font-size: 27.5px;
        line-height: 30px;
        font-family: fm;
        color: gray;
      }
      .body{
        font-size: 17px;
        line-height: 19px;
        margin-top: 20px;
      }
      .b1{
        font-size: 20px;
        padding: 10px;
        border-radius: 100px;
        background-color: #0071e3;
        color: white;
        display: block;
        margin: 30px;
        width: 150px;
      }
    }
    .d2{
      height: 300px;
      width: 300px;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: #fff;
      v-img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .prodsec2{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
    .title1{
      font-family: fb;
      font-size: 35px;
    }
    .d1{
      height: auto;
      width: auto;
      flex-shrink: 1;
      flex-grow: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .sd1{
        height: auto;
        min-height: 325px;
        width: 325px;
        background-color: #0071e3;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        .title2{
          font-size: 33px;
          line-height: 35px;
          font-family: fm;
        }
        .body{
          font-size: 18px;
        }
        .b1{
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: black;
          color: white;
          border-radius: 100px;
        }
        .b1:hover{
          background-color: #00000099;
        }
      }
    }
  }
  .prodsec3{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 100px;
    .title1{
      font-family: fb;
      font-size: 35px;
    }
    .body{
      font-size: 18px;
      line-height: 20px;
    }
  }
  .prodsec4{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px;
    justify-content: center;
    .b1{
      font-size: 19px;
      line-height: 21px;
      margin: 6px;
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px;
      color: white;
      background-color: #0071e3;
      border-radius: 100px;
    }
    .b1:hover{
      opacity: 0.8;
    }
  }
  .fm1{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;
    .d1{
      display: flex;
      flex-direction: column;
      .sd1{
        display: flex;
        flex-direction: column;
        .logo{
          width: 200px;
          height: 200px;
        }
      }
      .sd2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        gap: 50px;
        .s1{
          display: flex;
          flex-direction: column;
          color: #fff;
          gap: 5px;
          .title{
            color: rgb(142, 142, 142);
            font-size: 17.5px;
          }
          .link{
            font-size: 20px;
            cursor: pointer;
          }
          .link.fb:hover{
            color: #1877f2;
          }
          .link.ig:hover{
            color: #fe0b81;
          }
          .link.x:hover{
            color: #1DA1F2;
          }
          .link.ln:hover{
            color: #0077b5;
          }
          .link.n:hover{
            text-decoration: underline;
          }
        }
      }
    }
    .d2{
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;
      .title{
        width: 100%;
        text-align: center;
        font-family: fl;
        color: white;
      }
    }
  }
}
</style>
