<template>
  <v-app class="app">
    <v-main>
      <template v-if="cpage === 'home'">
        <HelloWorld />
      </template>
      <template v-else-if="cpage === 'terms'">
        <Terms />
      </template>
      <template v-else-if="cpage === 'privacy'">
        <Privacy />
      </template>
      <template v-else-if="cpage === 'contact'">
        <Contact />
      </template>
      <template v-else-if="cpage === 'about'">
        <About />
      </template>
      <template v-else-if="cpage === 'services'">
        <Services />
      </template>
      <template v-else>
        <Error404 />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Terms from './components/Terms.vue';
import Privacy from './components/Privacy.vue';
import Error404 from './components/e404.vue';
import Contact from './components/Contact.vue';
import About from './components/About.vue';
import Services from './components/Services.vue';

export default {
  name: 'App',

  components: {
    HelloWorld,
    Terms,
    Privacy,
    Error404,
    Contact,
    About,
    Services
},

  data: () => ({
    cpage: window.location.pathname.slice(1)
  }),
  created() {
    this.cpage = window.location.pathname.slice(1) === '' ? 'home' : window.location.pathname.slice(1);
    console.log(this.cpage)
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: fl;
  src: url('./assets/futura/FUTURA45LIGHT.TTF');
}

@font-face {
  font-family: fr;
  src: url('./assets/futura/FUTURA55REGULAR.TTF');
}

@font-face {
  font-family: fm;
  src: url('./assets/futura/FUTURA65MEDIUM.TTF');
}

@font-face {
  font-family: fli;
  src: url('./assets/futura/FUTURA46LIGHTITALIC.TTF');
}

@font-face {
  font-family: fi;
  src: url('./assets/futura/FUTURA56ITALIC.TTF');
}

@font-face {
  font-family: fmi;
  src: url('./assets/futura/FUTURA66MEDIUMITALIC.TTF');
}

@font-face {
  font-family: fb;
  src: url('./assets/futura/FUTURA75BOLD.TTF');
}

@font-face {
  font-family: fbi;
  src: url('./assets/futura/FUTURA76BOLDITALIC.TTF');
}

.app {
  font-family: fr;
}</style>
