<template>
  <header class="hm1">
    <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
    <nav class="navbar">
      <a class="button" href="/home" id="nav_/home">Home</a>
      <a class="button" href="/about" id="nav_/about">About</a>
      <a class="button" href="/services" id="nav_/services">Services</a>
      <a class="button" href="/contact" id="nav_/contact">Contact</a>
    </nav>
  </header>
  <section class="asec1" style="margin-top: 250px;">
    <p class="title">Services</p>
    <p class="subtitle">Tailored to Empower Your Business</p>
    <div class="d1">
      <p class="t1" style="font-family: fr;">we understand that every business has unique needs and challenges. That's why
        we offer a comprehensive suite of services designed to bridge the gap between your online and offline operations,
        streamline workflows, and propel you towards success.</p>
    </div>
  </section>
  <section class="sprodsec2" style="margin-top:50px;margin-bottom:100px;">
    <div class="d1">
      <div class="sd1" style="background-color: #2A3439;color:#fff">
        <p class="title2">Software Consulting & Development</p>
        <p class="body">Our team of skilled IT consultants will work closely with you to understand your specific needs
          and challenges.
          We provide comprehensive consulting services, from feasibility studies and system analysis to solution design
          and implementation.
          Our expert developers craft bespoke software solutions tailored to your unique workflows and business processes,
          ensuring a perfect fit.</p>
        <a class="b1" href="contact" style="cursor: pointer;background-color:white;color:black;">Contact Us</a>
      </div>
      <div class="sd1" style="background-color: #E60026;color:#fff">
        <p class="title2">Cloud-Based SaaS Solutions (Zaqnet & ZaqEcosystem)</p>
        <p class="body">We are proud to introduce Zaqnet, a powerful cloud-based SaaS platform that serves as the
          foundation for your business needs.
          Zaqnet streamlines core operations, centralizes data, and fosters collaboration within a user-friendly
          interface.
          ZaqEcosystem takes it a step further, creating a connected environment where businesses and consumers interact
          seamlessly.
          This empowers manufacturers, retailers, and consumers to collaborate effectively and enjoy a frictionless
          experience.</p>
        <a class="b1" href="services" target="_blank"
          style="cursor: pointer;background-color:white;color:black;">Learn More</a>
      </div>
    </div>
  </section>
  <footer class="fm1">
    <div class="d1">
      <div class="sd1">
        <v-img class="logo" :src="require('../assets/logo3.png')" />
      </div>
      <div class="sd2">
        <div class="s1">
          <p class="title">General</p>
          <a class="link n" href="about">About Us</a>
          <a class="link n" href="services">Services</a>
          <a class="link n" href="contact">Contact Us</a>
          <p class="title">Products</p>
          <a class="link n" href="services" target="_blank">Zaqnet</a>
        </div>
        <div class="s1">
          <p class="title">Socials</p>
          <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"><span
              class="mdi mdi-facebook"></span> Facebook</a>
          <a class="link ig" href="https://www.instagram.com/rdscorpglobal/" target="_blank"><span
              class="mdi mdi-instagram"></span> Instagram</a>
          <a class="link x" href="https://www.x.com/rdscorpglobal/" target="_blank"><span class="mdi mdi-twitter"></span>
            Twitter</a>
          <a class="link ln" href="https://www.linkedin.com/company/rdscorp/" target="_blank"><span
              class="mdi mdi-linkedin"></span> LinkedIn</a>
        </div>
        <div class="s1">
          <p class="title">Terms & Policies</p>
          <a class="link n" href="terms">Terms & Conditions</a>
          <a class="link n" href="privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="d2">
      <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
    </div>
  </footer>
</template>
  
<script>

export default {
  name: 'ServicesPage',
  data: () => ({
  }),
  mounted() {
    document.title = 'RDS Corp'
    if (document.getElementById(`nav_${window.location.pathname}`)) {
      document.getElementById(`nav_${window.location.pathname}`).classList.add('selected')
    }
  }
}
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}

.hm1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, #fff 90%, transparent 100%);
  padding: 20px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 80px;
    width: 80px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  nav {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    gap: 25px;

    .button {
      background-color: rgb(229, 229, 229);
      height: 40px;
      width: auto;
      border-radius: 1000px;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      display: grid;
      place-content: center;
    }

    .button:hover {
      outline: 2px solid #046fdb;
      outline-offset: 2px;
    }

    .button.selected {
      background-color: black;
      color: white;
    }
  }
}

.aprodsec1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  gap: 100px;
  align-items: center;

  .d1 {
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .title {
      font-family: fbi;
      font-size: 55px;

      span {
        font-family: fb;
        color: red;
      }
    }

    .subtitle {
      font-size: 35px;
      font-family: fm;
      color: gray;
    }

    .body {
      font-size: 20px;
      margin-top: 20px;
    }

    .b1 {
      font-size: 20px;
      padding: 10px;
      border-radius: 100px;
      background-color: #0071e3;
      color: white;
      display: block;
      margin: 30px;
      width: 150px;
    }
  }

  .d2 {
    height: 400px;
    width: 400px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #fff;

    v-img {
      width: 100%;
      height: 100%;
    }
  }
}

.sprodsec2 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .title1 {
    font-family: fb;
    font-size: 50px;
  }

  .subtitle1 {
    font-family: fm;
    font-size: 25px;
  }

  .d1 {
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    gap: 30px;

    .sd1 {
      height: auto;
      min-height: 400px;
      width: 50%;
      background-color: #0071e3;
      padding: 50px;
      display: flex;
      gap: 30px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .title2 {
        font-size: 35px;
        font-family: fm;
      }

      .body {
        font-size: 20px;
      }

      .b1 {
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        color: white;
        border-radius: 100px;
      }

      .b1:hover {
        background-color: #00000099;
      }
    }
  }
}

.aprodsec3 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 150px;

  .title1 {
    font-family: fb;
    font-size: 55px;
  }

  .body {
    font-size: 25px;
  }
}

.aprodsec4 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
  justify-content: center;

  .b1 {
    font-size: 20px;
    margin: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background-color: #0071e3;
    border-radius: 100px;
  }

  .b1:hover {
    opacity: 0.8;
  }
}

.fm1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: rgb(0, 0, 0);
  padding-top: 20px;

  .d1 {
    display: flex;
    justify-content: space-between;

    .sd1 {
      display: flex;
      flex-direction: column;

      .logo {
        width: 250px;
        height: 250px;
      }
    }

    .sd2 {
      display: flex;
      padding-top: 30px;
      gap: 50px;

      .s1 {
        display: flex;
        flex-direction: column;
        color: #fff;
        gap: 5px;

        .title {
          color: rgb(142, 142, 142);
          font-size: 17.5px;
        }

        .link {
          font-size: 20px;
          cursor: pointer;
        }

        .link.fb:hover {
          color: #1877f2;
        }

        .link.ig:hover {
          color: #fe0b81;
        }

        .link.x:hover {
          color: #1DA1F2;
        }

        .link.ln:hover {
          color: #0077b5;
        }

        .link.n:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .d2 {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;

    .title {
      width: 100%;
      text-align: center;
      font-family: fl;
      color: white;
    }
  }
}

.asec1 {
  padding-left: 150px;
  padding-right: 150px;

  .title {
    font-family: fm;
    font-size: 65px;
  }

  .subtitle {
    font-size: 35px;
    color: gray;
  }

  .d1 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .t1 {
      font-family: fm;
      font-size: 27.5px;
    }

    .b1 {
      font-size: 22.5px;
    }

    .bpt {
      font-size: 22.5px;
      font-family: fm;
    }

    ul {
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

}

@media (max-width: 768px) and (orientation: portrait) {
  .hm1 {
    padding: 30px;
    height: 150px;

    .logo {
      width: 70px;
      height: 70px;
    }

    nav {
      display: none;
    }
  }

  .asec1 {
    padding-left: 30px;
    padding-right: 30px;
    .title {
      font-family: fm;
      font-size: 50px;
    }
  
    .subtitle {
      font-size: 30px;
      color: gray;
    }
  
    .d1 {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .t1 {
        font-family: fm;
        font-size: 22.5px;
      }
  
      .b1 {
        font-size: 20px;
      }
  
      .bpt {
        font-size: 20px;
        font-family: fm;
      }
  
      ul {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  
  }
  .sprodsec2 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
  
    .title1 {
      font-family: fb;
      font-size: 35px;
      line-height: 38px;
    }
  
    .subtitle1 {
      font-family: fm;
      font-size: 22.5px;
      line-height: 26px;
    }
  
    .d1 {
      height: auto;
      width: auto;
      flex-shrink: 1;
      flex-grow: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .sd1 {
        height: auto;
        min-height: 325px;
        width: 325px;
        background-color: #0071e3;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
  
        .title2 {
          font-size: 35px;
          font-family: fm;
        }
  
        .body {
          font-size: 20px;
        }
  
        .b1 {
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: black;
          color: white;
          border-radius: 100px;
        }
  
        .b1:hover {
          background-color: #00000099;
        }
      }
    }
  }

  .fm1 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;

    .d1 {
      display: flex;
      flex-direction: column;

      .sd1 {
        display: flex;
        flex-direction: column;

        .logo {
          width: 200px;
          height: 200px;
        }
      }

      .sd2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        gap: 50px;

        .s1 {
          display: flex;
          flex-direction: column;
          color: #fff;
          gap: 5px;

          .title {
            color: rgb(142, 142, 142);
            font-size: 17.5px;
          }

          .link {
            font-size: 20px;
            cursor: pointer;
          }

          .link.fb:hover {
            color: #1877f2;
          }

          .link.ig:hover {
            color: #fe0b81;
          }

          .link.x:hover {
            color: #1DA1F2;
          }

          .link.ln:hover {
            color: #0077b5;
          }

          .link.n:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .d2 {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;

      .title {
        width: 100%;
        text-align: center;
        font-family: fl;
        color: white;
      }
    }
  }
}


</style>
  