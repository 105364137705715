<template>
  <header class="hm1">
    <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
    <nav class="navbar">
      <a class="button" href="/home" id="nav_/home">Home</a>
      <a class="button" href="/about" id="nav_/about">About</a>
      <a class="button" href="/services" id="nav_/services">Services</a>
      <a class="button" href="/contact" id="nav_/contact">Contact</a>
    </nav>
  </header>
  <section class="sec1">
    <p class="title">Terms & Conditions</p>
    <p class="subtitle">Effective May 1, 2024</p>
    <div class="d1">
        <p class="t1">1. INTRODUCTION</p>
        <p class="b1">Welcome to RDS Corp. These Terms & Conditions ("Terms") govern your use of our website <a href="https://rajputanards.com/" style="cursor: pointer;color:#0071e3;">https://rajputanards.com/</a> and our software development and digital product services. By accessing or using our services, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use our services.</p>
    </div>
    
    <div class="d1">
        <p class="t1">2. DEFINITIONS</p>
        <ul>
            <li class="b1">
                <span class="bpt">"We," "Us," and "Our":</span> refer to RDS Corp.
            </li>
            <li class="b1">
                <span class="bpt">"You" and "Your":</span> refer to the user of our website and services.
            </li>
            <li class="b1">
                <span class="bpt">"Services":</span> refer to the software development, digital products, and SaaS solutions provided by RDS Corp.
            </li>
        </ul>
    </div>
    
    <div class="d1">
        <p class="t1">3. ELIGIBILITY</p>
        <p class="b1">You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this eligibility requirement and that all information you provide is accurate and complete. If you are using our services on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these Terms.</p>
    </div>
    
    <div class="d1">
        <p class="t1">4. USE OF SERVICES</p>
        <ul>
            <li class="b1">
                <span class="bpt">Account Creation:</span> To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account.
            </li>
            <li class="b1">
                <span class="bpt">User Responsibilities:</span> You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for all content you upload, post, or otherwise transmit through our services.
            </li>
            <li class="b1">
                <span class="bpt">Prohibited Activities:</span> You agree not to:
                <ul>
                    <li class="b1">Use our services for any illegal or unauthorized purpose.</li>
                    <li class="b1">Interfere with or disrupt the integrity or performance of our services.</li>
                    <li class="b1">Attempt to gain unauthorized access to our services or related systems or networks.</li>
                    <li class="b1">Upload, post, or transmit any material that contains viruses or other harmful computer code, files, or programs.</li>
                    <li class="b1">Use our services to transmit spam, phishing emails, or other unsolicited messages.</li>
                </ul>
            </li>
        </ul>
    </div>
    
    <div class="d1">
        <p class="t1">5. PAYMENTS AND FEES</p>
        <ul>
            <li class="b1">
                <span class="bpt">Subscription Fees:</span> Certain services may require the payment of subscription fees. All fees are described on our website and are subject to change. By subscribing to our services, you agree to pay all applicable fees.
            </li>
            <li class="b1">
                <span class="bpt">Payment Information:</span> You must provide accurate and complete payment information. By providing payment details, you authorize us to charge the applicable fees to your payment method. If your payment method fails or your account is past due, we may collect fees owed using other collection mechanisms.
            </li>
            <li class="b1">
                <span class="bpt">Refunds:</span> All fees are non-refundable unless otherwise stated in a specific refund policy provided at the time of purchase.
            </li>
        </ul>
    </div>
    
    <div class="d1">
        <p class="t1">6. INTELLECTUAL PROPERTY</p>
        <ul>
            <li class="b1">
                <span class="bpt">Ownership:</span> All content, software, and materials on our website and services, including but not limited to text, graphics, logos, and software, are the property of RDS Corp or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works from any content without our express written permission.
            </li>
            <li class="b1">
                <span class="bpt">License:</span> We grant you a limited, non-exclusive, non-transferable license to access and use our services for your personal or internal business purposes, subject to these Terms. This license does not include the right to: resell our services; scrape, reproduce, or distribute any content; or use our services for any commercial use without our prior written consent.
            </li>
        </ul>
    </div>
    
    <div class="d1">
        <p class="t1">7. LIMITATION OF LIABILITY</p>
        <p class="b1">To the maximum extent permitted by law, RDS Corp shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
            <li class="b1">Your use or inability to use our services.</li>
            <li class="b1">Unauthorized access to or use of your account or data.</li>
            <li class="b1">Any conduct or content of any third party on our services.</li>
            <li class="b1">Any content obtained from our services.</li>
        </ul>
        <p class="b1">In no event shall our aggregate liability for all claims relating to our services exceed the amount you paid us in the past twelve months for the services in question.</p>
    </div>
    
    <div class="d1">
        <p class="t1">8. INDEMNIFICATION</p>
        <p class="b1">You agree to indemnify, defend, and hold harmless RDS Corp, its affiliates, and their respective officers, directors, employees, agents, licensors, and suppliers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of our services, including, but not limited to, your user content, any use of our services other than as expressly authorized in these Terms, or your use of any information obtained from our services.</p>
    </div>
    
    <div class="d1">
        <p class="t1">9. TERMINATION</p>
        <p class="b1">We reserve the right to suspend or terminate your access to our services at any time, without notice, for any reason, including but not limited to your breach of these Terms. Upon termination, your right to use our services will immediately cease. We also reserve the right to remove or disable access to any content or other materials that we believe, in our sole discretion, violate these Terms or are otherwise harmful to our services.</p>
    </div>
    
    <div class="d1">
        <p class="t1">10. GOVERNING LAW</p>
        <p class="b1">These Terms are governed by and construed in accordance with the laws of India. Any disputes arising out of or relating to these Terms or your use of our services shall be subject to the exclusive jurisdiction of the courts in Jaipur, Rajasthan, India. You agree to submit to the jurisdiction of such courts for any legal action or proceeding arising out of or relating to these Terms or your use of our services.</p>
    </div>
    
    <div class="d1">
        <p class="t1">11. CHANGES TO TERMS</p>
        <p class="b1">We may update these Terms from time to time at our sole discretion. We will notify you of any changes by posting the new Terms on our website and, where possible, via email. You are advised to review these Terms periodically for any changes. Your continued use of our services following the posting of the revised Terms constitutes your acceptance of those changes.</p>
    </div>
    
    <div class="d1" style="margin-bottom: 100px;">
        <p class="t1">12. CONTACT US</p>
        <p class="b1">If you have any questions or concerns about these Terms, please contact us at:</p>
        <ul>
            <li class="b1">
                <span class="bpt">Email:</span> <a style="color:#0071e3;cursor:pointer" href="mailto:support@rajputanards.com">support@rajputanards.com</a>
            </li>
            <li class="b1">
                <span class="bpt">Phone:</span> <a style="color:#0071e3;cursor:pointer" href="tel:+917424800535">+91 7424800535</a>
            </li>
            <li class="b1">
                <span class="bpt">Address:</span> 3, Van Vihar Colony, Tonk Road, Jaipur-302015, Rajasthan, India
            </li>
        </ul>
    </div>
    
</section>
  <footer class="fm1">
    <div class="d1">
      <div class="sd1">
        <v-img class="logo" :src="require('../assets/logo3.png')" />
      </div>
      <div class="sd2">
        <div class="s1">
          <p class="title">General</p>
          <a class="link n" href="about">About Us</a>
          <a class="link n" href="services">Services</a>
          <a class="link n" href="contact">Contact Us</a>
          <p class="title">Products</p>
          <a class="link n" href="services" target="_blank">Zaqnet</a>
        </div>
        <div class="s1">
          <p class="title">Socials</p>
          <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"
            ><span class="mdi mdi-facebook"></span> Facebook</a
          >
          <a
            class="link ig"
            href="https://www.instagram.com/rdscorpglobal/"
            target="_blank"
            ><span class="mdi mdi-instagram"></span> Instagram</a
          >
          <a class="link x" href="https://www.x.com/rdscorpglobal/" target="_blank"
            ><span class="mdi mdi-twitter"></span> Twitter</a
          >
          <a
            class="link ln"
            href="https://www.linkedin.com/company/rdscorp/"
            target="_blank"
            ><span class="mdi mdi-linkedin"></span> LinkedIn</a
          >
        </div>
        <div class="s1">
          <p class="title">Terms & Policies</p>
          <a class="link n" href="terms">Terms & Conditions</a>
          <a class="link n" href="privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="d2">
      <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TermsConditions",
  data: () => ({}),
  mounted() {
    document.title = "Terms & Conditions";
    if (document.getElementById(`nav_${window.location.pathname}`)) {
      document
        .getElementById(`nav_${window.location.pathname}`)
        .classList.add("selected");
    }
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}

.hm1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, #fff 90%, transparent 100%);
  padding: 20px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 80px;
    width: 80px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  nav {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    gap: 25px;

    .button {
      background-color: rgb(229, 229, 229);
      height: 40px;
      width: auto;
      border-radius: 1000px;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      display: grid;
      place-content: center;
    }

    .button:hover {
      outline: 2px solid #046fdb;
      outline-offset: 2px;
    }

    .button.selected {
      background-color: black;
      color: white;
    }
  }
}

.sec1{
    padding-left: 150px;
    padding-right: 150px;
    margin-top: 250px;
    .title{
        font-family: fm;
        font-size: 65px;
    }
    .subtitle{
        font-size: 35px;
        color: gray;
    }
    .d1{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .t1{
            font-family: fm;
            font-size: 27.5px;
        }
        .b1{
            font-size: 22.5px;
        }
        .bpt{
            font-size: 22.5px;
            font-family: fm;
        }
        ul{
            padding-left: 60px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

}

.fm1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: rgb(0, 0, 0);
  padding-top: 20px;

  .d1 {
    display: flex;
    justify-content: space-between;

    .sd1 {
      display: flex;
      flex-direction: column;

      .logo {
        width: 250px;
        height: 250px;
      }
    }

    .sd2 {
      display: flex;
      padding-top: 30px;
      gap: 50px;

      .s1 {
        display: flex;
        flex-direction: column;
        color: #fff;
        gap: 5px;

        .title {
          color: rgb(142, 142, 142);
          font-size: 17.5px;
        }

        .link {
          font-size: 20px;
          cursor: pointer;
        }

        .link.fb:hover {
          color: #1877f2;
        }

        .link.ig:hover {
          color: #fe0b81;
        }

        .link.x:hover {
          color: #1da1f2;
        }

        .link.ln:hover {
          color: #0077b5;
        }

        .link.n:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .d2 {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;

    .title {
      width: 100%;
      text-align: center;
      font-family: fl;
      color: white;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .hm1 {
    padding: 30px;
    height: 150px;

    .logo {
      width: 70px;
      height: 70px;
    }

    nav {
      display: none;
    }
  }

  .sec1{
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
    padding-top: 150px;
    .title{
        font-family: fm;
        font-size: 35px;
    }
    .subtitle{
        font-size: 25px;
        color: gray;
    }
    .d1{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .t1{
            font-family: fm;
            font-size: 22.5px;
        }
        .b1{
            font-size: 20px;
        }
        .bpt{
            font-size: 20px;
            font-family: fm;
        }
        ul{
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

}

  .fm1 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;

    .d1 {
      display: flex;
      flex-direction: column;

      .sd1 {
        display: flex;
        flex-direction: column;

        .logo {
          width: 200px;
          height: 200px;
        }
      }

      .sd2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        gap: 50px;

        .s1 {
          display: flex;
          flex-direction: column;
          color: #fff;
          gap: 5px;

          .title {
            color: rgb(142, 142, 142);
            font-size: 17.5px;
          }

          .link {
            font-size: 20px;
            cursor: pointer;
          }

          .link.fb:hover {
            color: #1877f2;
          }

          .link.ig:hover {
            color: #fe0b81;
          }

          .link.x:hover {
            color: #1DA1F2;
          }

          .link.ln:hover {
            color: #0077b5;
          }

          .link.n:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .d2 {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;

      .title {
        width: 100%;
        text-align: center;
        font-family: fl;
        color: white;
      }
    }
  }
}


</style>
