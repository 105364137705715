<template>
  <header class="hm1">
    <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
    <nav class="navbar">
      <a class="button" href="/home" id="nav_/home">Home</a>
      <a class="button" href="/about" id="nav_/about">About</a>
      <a class="button" href="/services" id="nav_/services">Services</a>
      <a class="button" href="/contact" id="nav_/contact">Contact</a>
    </nav>
  </header>
  <section class="asec1a" style="margin-top: 250px;">
    <p class="title">RDS Corp</p>
    <p class="subtitle">Bridging the Gap and Building the Future</p>
    <div class="d1">
      <p class="t1" style="font-family: fr;"><span style="font-family: fm;">Established in 2023,</span> RDS Corp is a
        trailblazing IT company dedicated to empowering businesses with innovative solutions that bridge the offline and
        online divide, streamlining operations and propelling them towards success.</p>
    </div>
    <div class="d1">
      <p class="t1">Our Core Expertise:</p>
      <p class="b1">We collect various types of information in connection with the services we provide, including:</p>
      <ul>
        <li class="b1">
          <span class="bpt">Software Consulting & Development:</span> We leverage our team of passionate IT professionals
          to provide comprehensive consulting services, meticulously crafting custom software solutions tailored to your
          specific needs and workflows.
        </li>
        <li class="b1">
          <span class="bpt">Seamless Online-Offline Integration:</span> We understand the challenges of operating in a
          hybrid world. Our solutions seamlessly integrate your online and offline operations, fostering a unified and
          efficient ecosystem.
        </li>
      </ul>
    </div>
  </section>
  <section class="aprodsec2" style="margin-top:100px;">
    <p class="title1">Introducing Zaqnet & ZaqEcosystem</p>
    <p class="subtitle1">We are thrilled to introduce Zaqnet and ZaqEcosystem, a revolutionary cloud-based SaaS (Software
      as a Service) platform designed to empower businesses and consumers alike.</p>
  </section>
  <section class="asec1a">
    <div class="d1">
      <p class="t1">Zaqnet:</p>
      <p class="b1">Zaqnet acts as the foundation, a powerful cloud suite that caters to all your operational needs.
        Imagine a comprehensive suite that streamlines processes, eliminates data silos, and fosters collaboration – all
        within a single, user-friendly platform.</p>
    </div>
    <div class="d1">
      <p class="t1">ZaqEcosystem:</p>
      <p class="b1">Taking things a step further, ZaqEcosystem goes beyond, fostering a connected environment where
        businesses and consumers seamlessly interact. This ecosystem empowers:</p>
    </div>
  </section>
  <section class="aprodsec2" style="margin-top:50px;">
    <div class="d1">
      <div class="sd1" style="background-color: #8e4585;color:#fff">
        <p class="title2">Manufacturers & Brand Owners</p>
        <p class="body">Optimize production processes, streamline supply chains, and collaborate effectively with
          retailers.</p>
      </div>
      <div class="sd1" style="background-color: #324AB2;color:#fff">
        <p class="title2">Wholesalers & Retailers</p>
        <p class="body">Gain access to powerful tools for inventory management, customer engagement, and data-driven
          insights.</p>
      </div>
      <div class="sd1" style="background-color: #BF5700;color:#fff">
        <p class="title2">Consumers</p>
        <p class="body">Enjoy a frictionless shopping experience with personalized recommendations, transparent product
          information, and effortless communication with retailers and manufacturers.</p>
      </div>
    </div>
  </section>
  <section class="asec1a" style="margin-top: 100px;margin-bottom:100px;">
    <p class="title">A Vision for the Future</p>
    <div class="d1">
      <p class="t1">At RDS Corp, we are constantly innovating. While Zaqnet and ZaqEcosystem provide a robust foundation,
        we are already looking ahead. We are dedicated to developing next-generation consumer solutions, including
        cutting-edge payment apps and more, to further enhance the user experience within the ZaqEcosystem.</p>
    </div>
    <div class="d1">
      <p class="t1">Why Choose RDS Corp?</p>
      <ul>
        <li class="b1">
          <span class="bpt">Unparalleled Expertise:</span> Our team possesses a wealth of experience and a passion for
          crafting solutions that make a real difference.
        </li>
        <li class="b1">
          <span class="bpt">Future-Proof Technology:</span> We leverage cutting-edge technologies to ensure your solutions
          are scalable and adaptable to evolving industry needs.
        </li>
        <li class="b1">
          <span class="bpt">A Holistic Approach:</span> We go beyond simply providing software; we bridge the
          online-offline gap, fostering a unified and efficient business ecosystem.
        </li>
        <li class="b1">
          <span class="bpt">Commitment to Innovation:</span> We are constantly pushing boundaries, developing new
          solutions like Zaqnet and ZaqEcosystem, with a vision for the future of commerce.
        </li>
      </ul>
    </div>
    <div class="d1">
      <p class="t1">Partner with RDS Corp and unlock the full potential of your business. Let's bridge the gap and build
        the future, together!</p>
    </div>
  </section>
  <footer class="fm1">
    <div class="d1">
      <div class="sd1">
        <v-img class="logo" :src="require('../assets/logo3.png')" />
      </div>
      <div class="sd2">
        <div class="s1">
          <p class="title">General</p>
          <a class="link n" href="about">About Us</a>
          <a class="link n" href="services">Services</a>
          <a class="link n" href="contact">Contact Us</a>
          <p class="title">Products</p>
          <a class="link n" href="services" target="_blank">Zaqnet</a>
        </div>
        <div class="s1">
          <p class="title">Socials</p>
          <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"><span
              class="mdi mdi-facebook"></span> Facebook</a>
          <a class="link ig" href="https://www.instagram.com/rdscorpglobal/" target="_blank"><span
              class="mdi mdi-instagram"></span> Instagram</a>
          <a class="link x" href="https://www.x.com/rdscorpglobal/" target="_blank"><span class="mdi mdi-twitter"></span>
            Twitter</a>
          <a class="link ln" href="https://www.linkedin.com/company/rdscorp/" target="_blank"><span
              class="mdi mdi-linkedin"></span> LinkedIn</a>
        </div>
        <div class="s1">
          <p class="title">Terms & Policies</p>
          <a class="link n" href="terms">Terms & Conditions</a>
          <a class="link n" href="privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="d2">
      <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
    </div>
  </footer>
</template>
  
<script>

export default {
  name: 'AboutUs',
  data: () => ({
  }),
  mounted() {
    document.title = 'RDS Corp'
    if (document.getElementById(`nav_${window.location.pathname}`)) {
      document.getElementById(`nav_${window.location.pathname}`).classList.add('selected')
    }
  }
}
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}

.hm1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, #fff 90%, transparent 100%);
  padding: 20px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 80px;
    width: 80px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  nav {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    gap: 25px;

    .button {
      background-color: rgb(229, 229, 229);
      height: 40px;
      width: auto;
      border-radius: 1000px;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      display: grid;
      place-content: center;
    }

    .button:hover {
      outline: 2px solid #046fdb;
      outline-offset: 2px;
    }

    .button.selected {
      background-color: black;
      color: white;
    }
  }
}

.aprodsec1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  gap: 100px;
  align-items: center;

  .d1 {
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .title {
      font-family: fbi;
      font-size: 55px;

      span {
        font-family: fb;
        color: red;
      }
    }

    .subtitle {
      font-size: 35px;
      font-family: fm;
      color: gray;
    }

    .body {
      font-size: 20px;
      margin-top: 20px;
    }

    .b1 {
      font-size: 20px;
      padding: 10px;
      border-radius: 100px;
      background-color: #0071e3;
      color: white;
      display: block;
      margin: 30px;
      width: 150px;
    }
  }

  .d2 {
    height: 400px;
    width: 400px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #fff;

    v-img {
      width: 100%;
      height: 100%;
    }
  }
}

.aprodsec2 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .title1 {
    font-family: fb;
    font-size: 50px;
  }

  .subtitle1 {
    font-family: fm;
    font-size: 25px;
  }

  .d1 {
    height: auto;
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    gap: 30px;

    .sd1 {
      height: auto;
      min-height: 400px;
      width: 400px;
      background-color: #0071e3;
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      .title2 {
        font-size: 35px;
        font-family: fm;
      }

      .body {
        font-size: 20px;
      }

      .b1 {
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: black;
        color: white;
        border-radius: 100px;
      }

      .b1:hover {
        background-color: #00000099;
      }
    }
  }
}

.aprodsec3 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 150px;

  .title1 {
    font-family: fb;
    font-size: 55px;
  }

  .body {
    font-size: 25px;
  }
}

.aprodsec4 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  height: auto;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
  justify-content: center;

  .b1 {
    font-size: 20px;
    margin: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background-color: #0071e3;
    border-radius: 100px;
  }

  .b1:hover {
    opacity: 0.8;
  }
}

.fm1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: rgb(0, 0, 0);
  padding-top: 20px;

  .d1 {
    display: flex;
    justify-content: space-between;

    .sd1 {
      display: flex;
      flex-direction: column;

      .logo {
        width: 250px;
        height: 250px;
      }
    }

    .sd2 {
      display: flex;
      padding-top: 30px;
      gap: 50px;

      .s1 {
        display: flex;
        flex-direction: column;
        color: #fff;
        gap: 5px;

        .title {
          color: rgb(142, 142, 142);
          font-size: 17.5px;
        }

        .link {
          font-size: 20px;
          cursor: pointer;
        }

        .link.fb:hover {
          color: #1877f2;
        }

        .link.ig:hover {
          color: #fe0b81;
        }

        .link.x:hover {
          color: #1DA1F2;
        }

        .link.ln:hover {
          color: #0077b5;
        }

        .link.n:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .d2 {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;

    .title {
      width: 100%;
      text-align: center;
      font-family: fl;
      color: white;
    }
  }
}

.asec1a {
  padding-left: 150px;
  padding-right: 150px;

  .title {
    font-family: fm;
    font-size: 65px;
  }

  .subtitle {
    font-size: 35px;
    color: gray;
  }

  .d1 {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .t1 {
      font-family: fm;
      font-size: 27.5px;
    }

    .b1 {
      font-size: 22.5px;
    }

    .bpt {
      font-size: 22.5px;
      font-family: fm;
    }

    ul {
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

}

@media (max-width: 768px) and (orientation: portrait) {
  .hm1 {
    padding: 30px;
    height: 150px;

    .logo {
      width: 70px;
      height: 70px;
    }

    nav {
      display: none;
    }
  }

  .asec1a {
    padding-left: 30px;
    padding-right: 30px;
  
    .title {
      font-family: fm;
      font-size: 50px;
    }
  
    .subtitle {
      font-size: 30px;
      color: gray;
    }
  
    .d1 {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .t1 {
        font-family: fm;
        font-size: 22.5px;
      }
  
      .b1 {
        font-size: 20px;
      }
  
      .bpt {
        font-size: 20px;
        font-family: fm;
      }
  
      ul {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  
  }
  .aprodsec2 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
  
    .title1 {
      font-family: fb;
      font-size: 35px;
      line-height: 38px;
    }
  
    .subtitle1 {
      font-family: fm;
      font-size: 22.5px;
      line-height: 26px;
    }
  
    .d1 {
      height: auto;
      width: auto;
      flex-shrink: 1;
      flex-grow: 1;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .sd1 {
        height: auto;
        min-height: 325px;
        width: 325px;
        background-color: #0071e3;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
  
        .title2 {
          font-size: 35px;
          font-family: fm;
        }
  
        .body {
          font-size: 20px;
        }
  
        .b1 {
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: black;
          color: white;
          border-radius: 100px;
        }
  
        .b1:hover {
          background-color: #00000099;
        }
      }
    }
  }

  .fm1 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;

    .d1 {
      display: flex;
      flex-direction: column;

      .sd1 {
        display: flex;
        flex-direction: column;

        .logo {
          width: 200px;
          height: 200px;
        }
      }

      .sd2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        gap: 50px;

        .s1 {
          display: flex;
          flex-direction: column;
          color: #fff;
          gap: 5px;

          .title {
            color: rgb(142, 142, 142);
            font-size: 17.5px;
          }

          .link {
            font-size: 20px;
            cursor: pointer;
          }

          .link.fb:hover {
            color: #1877f2;
          }

          .link.ig:hover {
            color: #fe0b81;
          }

          .link.x:hover {
            color: #1DA1F2;
          }

          .link.ln:hover {
            color: #0077b5;
          }

          .link.n:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .d2 {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;

      .title {
        width: 100%;
        text-align: center;
        font-family: fl;
        color: white;
      }
    }
  }
}

</style>
  