<template>
    <header class="hm1">
        <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
        <nav class="navbar">
            <a class="button" href="/home" id="nav_/home">Home</a>
            <a class="button" href="/about" id="nav_/about">About</a>
            <a class="button" href="/services" id="nav_/services">Services</a>
            <a class="button" href="/contact" id="nav_/contact">Contact</a>
        </nav>
    </header>
    <section class="sec1">
        <p class="title">Privacy Policy</p>
        <p class="subtitle">Effective May 1, 2024</p>
        <div class="d1">
            <p class="t1">1. INTRODUCTION</p>
            <p class="b1">RDS Corp ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://rajputanards.com/" style="color: #0071e3;cursor:pointer">https://rajputanards.com/</a> and use our software development and digital product services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
        </div>
        <div class="d1">
            <p class="t1">2. INFORMATION WE COLLECT</p>
            <p class="b1">We collect various types of information in connection with the services we provide, including:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">Personal Information:</span> This may include your name, email address, and payment information such as credit card details.
                </li>
                <li class="b1">
                    <span class="bpt">Usage Data:</span> Information about how you use our website and services, including the pages you visit and the actions you take.
                </li>
                <li class="b1">
                    <span class="bpt">Technical Data:</span> Internet Protocol (IP) address, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.
                </li>
            </ul>
        </div>
        
        <div class="d1">
            <p class="t1">3. HOW WE USE YOUR INFORMATION</p>
            <p class="b1">We use the information we collect for several purposes, including:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">To Provide and Improve Our Services:</span> Including processing your transactions, managing your account, and providing customer support.
                </li>
                <li class="b1">
                    <span class="bpt">Marketing:</span> To send you promotional materials and newsletters, subject to your preferences.
                </li>
                <li class="b1">
                    <span class="bpt">Notifications:</span> To inform you about updates, security alerts, and other relevant information related to our services.
                </li>
                <li class="b1">
                    <span class="bpt">Compliance and Protection:</span> To comply with legal obligations, resolve disputes, and enforce our agreements.
                </li>
            </ul>
        </div>
        
        <div class="d1">
            <p class="t1">4. INFORMATION SHARING AND DISCLOSURE</p>
            <p class="b1">We may share your information with third parties in the following situations:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">With Service Providers:</span> To perform functions on our behalf, such as payment processing and marketing.
                </li>
                <li class="b1">
                    <span class="bpt">For Legal Reasons:</span> If required by law or in response to valid requests by public authorities (e.g., a court or a government agency).
                </li>
                <li class="b1">
                    <span class="bpt">Business Transfers:</span> In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.
                </li>
            </ul>
        </div>
        
        <div class="d1">
            <p class="t1">5. DATA SECURITY</p>
            <p class="b1">We employ a variety of security measures to ensure the safety of your personal information:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">Encryption:</span> All data transmitted between your device and our servers is encrypted using SSL technology.
                </li>
                <li class="b1">
                    <span class="bpt">Data Storage:</span> Sensitive data is encrypted in storage, and highly sensitive information, such as credit card numbers, is hashed.
                </li>
                <li class="b1">
                    <span class="bpt">Access Controls:</span> Access to personal data is restricted to authorized personnel only.
                </li>
            </ul>
        </div>
        
        <div class="d1">
            <p class="t1">6. YOUR DATA PROTECTION RIGHTS</p>
            <p class="b1">Depending on your location, you may have the following rights regarding your personal data:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">Access:</span> Request access to your personal data.
                </li>
                <li class="b1">
                    <span class="bpt">Correction:</span> Request correction of any inaccuracies in your personal data.
                </li>
                <li class="b1">
                    <span class="bpt">Deletion:</span> Request deletion of your personal data. Note that we may retain certain information as required by law or for legitimate business purposes, and it may take up to one year to fully remove your data from our systems.
                </li>
                <li class="b1">
                    <span class="bpt">Objection:</span> Object to processing your personal data, under certain conditions.
                </li>
                <li class="b1">
                    <span class="bpt">Data Portability:</span> Request the transfer of your data to another organization or directly to you, under certain conditions.
                </li>
            </ul>
            <p class="b1">To exercise these rights, please contact us at support@rajputanards.com.</p>
        </div>
        
        <div class="d1">
            <p class="t1">7. COOKIES AND TRACKING TECHNOLOGIES</p>
            <p class="b1">We use cookies and similar tracking technologies to:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">Enhance User Experience:</span> To remember your preferences and settings.
                </li>
                <li class="b1">
                    <span class="bpt">Analytics and Performance:</span> To understand how our website is used and to improve our services.
                </li>
                <li class="b1">
                    <span class="bpt">Security:</span> To protect against fraud and abuse.
                </li>
            </ul>
            <p class="b1">You can manage your cookie preferences through your browser settings. However, disabling cookies may affect the functionality of our website.</p>
        </div>
        
        <div class="d1">
            <p class="t1">8. CHANGES TO THIS PRIVACY POLICY</p>
            <p class="b1">We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and, where appropriate, via email. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
        </div>
        <div class="d1">
            <p class="t1">9. CHILDREN'S PRIVACY</p>
            <p class="b1">Our services are not intended for children under the age of 13. We do not knowingly collect personal data from children under 13. If we become aware that we have inadvertently received personal data from a child under 13, we will delete such information from our records.</p>
        </div>
        <div class="d1" style="margin-bottom: 100px;">
            <p class="t1">10. CONTACT US</p>
            <p class="b1">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <ul>
                <li class="b1">
                    <span class="bpt">Email:</span> <a style="color:#0071e3;cursor:pointer" href="mailto:support@rajputanards.com">support@rajputanards.com</a>
                </li>
                <li class="b1">
                    <span class="bpt">Phone:</span> <a style="color:#0071e3;cursor:pointer" href="tel:+917424800535">+91 7424800535</a>
                </li>
                <li class="b1">
                    <span class="bpt">Address:</span> 3, Van Vihar Colony, Tonk Road, Jaipur-302015, Rajasthan, India
                </li>
            </ul>
            <p class="b1">By using our services, you agree to the terms of this Privacy Policy. We encourage you to review this policy regularly to stay informed about our data protection practices.</p>
        </div>
        
    </section>
    <footer class="fm1">
        <div class="d1">
            <div class="sd1">
                <v-img class="logo" :src="require('../assets/logo3.png')" />
            </div>
            <div class="sd2">
                <div class="s1">
                    <p class="title">General</p>
                    <a class="link n" href="about">About Us</a>
                    <a class="link n" href="services">Services</a>
                    <a class="link n" href="contact">Contact Us</a>
                    <p class="title">Products</p>
                    <a class="link n" href="services" target="_blank">Zaqnet</a>
                </div>
                <div class="s1">
                    <p class="title">Socials</p>
                    <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"><span
                            class="mdi mdi-facebook"></span> Facebook</a>
                    <a class="link ig" href="https://www.instagram.com/rdscorpglobal/" target="_blank"><span
                            class="mdi mdi-instagram"></span> Instagram</a>
                    <a class="link x" href="https://www.x.com/rdscorpglobal/" target="_blank"><span
                            class="mdi mdi-twitter"></span> Twitter</a>
                    <a class="link ln" href="https://www.linkedin.com/company/rdscorp/" target="_blank"><span
                            class="mdi mdi-linkedin"></span> LinkedIn</a>
                </div>
                <div class="s1">
                    <p class="title">Terms & Policies</p>
                    <a class="link n" href="terms">Terms & Conditions</a>
                    <a class="link n" href="privacy">Privacy Policy</a>
                </div>
            </div>
        </div>
        <div class="d2">
            <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: "PrivacyPolicy",
    data: () => ({}),
    mounted() {
        document.title = "Privacy Policy";
        if (document.getElementById(`nav_${window.location.pathname}`)) {
            document
                .getElementById(`nav_${window.location.pathname}`)
                .classList.add("selected");
        }
    },
};
</script>
<style lang="scss">
a {
    text-decoration: none;
    color: inherit;
}

.hm1 {
    width: 100%;
    height: 150px;
    background: linear-gradient(180deg, #fff 90%, transparent 100%);
    padding: 20px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .logo {
        height: 80px;
        width: 80px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    nav {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        gap: 25px;

        .button {
            background-color: rgb(229, 229, 229);
            height: 40px;
            width: auto;
            border-radius: 1000px;
            font-size: 18px;
            padding-left: 20px;
            padding-right: 20px;
            display: grid;
            place-content: center;
        }

        .button:hover {
            outline: 2px solid #046fdb;
            outline-offset: 2px;
        }

        .button.selected {
            background-color: black;
            color: white;
        }
    }
}
.sec1{
    padding-left: 150px;
    padding-right: 150px;
    margin-top: 250px;
    .title{
        font-family: fm;
        font-size: 65px;
    }
    .subtitle{
        font-size: 35px;
        color: gray;
    }
    .d1{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        .t1{
            font-family: fm;
            font-size: 27.5px;
        }
        .b1{
            font-size: 22.5px;
        }
        .bpt{
            font-size: 22.5px;
            font-family: fm;
        }
        ul{
            padding-left: 60px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

}
.fm1 {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;

    .d1 {
        display: flex;
        justify-content: space-between;

        .sd1 {
            display: flex;
            flex-direction: column;

            .logo {
                width: 250px;
                height: 250px;
            }
        }

        .sd2 {
            display: flex;
            padding-top: 30px;
            gap: 50px;

            .s1 {
                display: flex;
                flex-direction: column;
                color: #fff;
                gap: 5px;

                .title {
                    color: rgb(142, 142, 142);
                    font-size: 17.5px;
                }

                .link {
                    font-size: 20px;
                    cursor: pointer;
                }

                .link.fb:hover {
                    color: #1877f2;
                }

                .link.ig:hover {
                    color: #fe0b81;
                }

                .link.x:hover {
                    color: #1da1f2;
                }

                .link.ln:hover {
                    color: #0077b5;
                }

                .link.n:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .d2 {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;

        .title {
            width: 100%;
            text-align: center;
            font-family: fl;
            color: white;
        }
    }
}
@media (max-width: 768px) and (orientation: portrait) {
    .hm1 {
      padding: 30px;
      height: 150px;
  
      .logo {
        width: 70px;
        height: 70px;
      }
  
      nav {
        display: none;
      }
    }
  
    .sec1{
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 50px;
      padding-top: 150px;
      .title{
          font-family: fm;
          font-size: 35px;
      }
      .subtitle{
          font-size: 25px;
          color: gray;
      }
      .d1{
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          gap: 30px;
          .t1{
              font-family: fm;
              font-size: 22.5px;
          }
          .b1{
              font-size: 20px;
          }
          .bpt{
              font-size: 20px;
              font-family: fm;
          }
          ul{
              padding-left: 30px;
              display: flex;
              flex-direction: column;
              gap: 20px;
          }
      }
  
  }
  
    .fm1 {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      background-color: rgb(0, 0, 0);
      padding-top: 20px;
  
      .d1 {
        display: flex;
        flex-direction: column;
  
        .sd1 {
          display: flex;
          flex-direction: column;
  
          .logo {
            width: 200px;
            height: 200px;
          }
        }
  
        .sd2 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding-top: 30px;
          gap: 50px;
  
          .s1 {
            display: flex;
            flex-direction: column;
            color: #fff;
            gap: 5px;
  
            .title {
              color: rgb(142, 142, 142);
              font-size: 17.5px;
            }
  
            .link {
              font-size: 20px;
              cursor: pointer;
            }
  
            .link.fb:hover {
              color: #1877f2;
            }
  
            .link.ig:hover {
              color: #fe0b81;
            }
  
            .link.x:hover {
              color: #1DA1F2;
            }
  
            .link.ln:hover {
              color: #0077b5;
            }
  
            .link.n:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .d2 {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        margin-top: 20px;
        padding-bottom: 20px;
  
        .title {
          width: 100%;
          text-align: center;
          font-family: fl;
          color: white;
        }
      }
    }
  }
</style>
  