<template>
  <header class="hm1">
    <a href="/"><v-img class="logo" :src="require('../assets/logo2.png')" /></a>
    <nav class="navbar">
      <a class="button" href="/home" id="nav_/home">Home</a>
      <a class="button" href="/about" id="nav_/about">About</a>
      <a class="button" href="/services" id="nav_/services">Services</a>
      <a class="button" href="/contact" id="nav_/contact">Contact</a>
    </nav>
  </header>
  <section class="csec1">
    <p class="title">Contact Us</p>
    <p class="subtitle" style="margin-bottom: 50px;">Submit your queries and we'll get back to you soon.</p>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="name" :rules="[v => !!v || 'Name is required']" label="Name" required></v-text-field>

        <v-text-field v-model="companyName" label="Company Name (Optional)"></v-text-field>

        <v-text-field v-model="email"
          :rules="[v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']" label="E-mail"
          required></v-text-field>

        <v-text-field v-model="phone"
          :rules="[v => !!v || 'Phone number is required', v => /^\d+$/.test(v) || 'Phone number must be valid']"
          label="Phone Number" required></v-text-field>

        <v-select v-model="heardFrom" :items="heardFromOptions" :rules="[v => !!v || 'This field is required']"
          label="Where did you hear about us?" required></v-select>

        <v-textarea v-model="message" label="Message"></v-textarea>

        <v-checkbox v-model="agree" :rules="[v => !!v || 'You must agree to continue!']"
          label="I agree with the privacy policy">
          <template v-slot:label>
            <span>
              I agree to share my information with RDS Corp and understand it will be used as described in their
              <a href="privacy" style="color: #0071e3;opacity:1" target="_blank">
                Privacy Policy.
              </a>
            </span>
          </template>
        </v-checkbox>

        <v-btn class="vbtn" :loading="formloading" :disabled="!valid" color="#0071e3" @click="submit" style="margin-bottom: 100px;">
          Submit
        </v-btn>
      </v-form>
    </v-container>
  </section>
  <footer class="fm1">
    <div class="d1">
      <div class="sd1">
        <v-img class="logo" :src="require('../assets/logo3.png')" />
      </div>
      <div class="sd2">
        <div class="s1">
          <p class="title">General</p>
          <a class="link n" href="about">About Us</a>
          <a class="link n" href="services">Services</a>
          <a class="link n" href="contact">Contact Us</a>
          <p class="title">Products</p>
          <a class="link n" href="services" target="_blank">Zaqnet</a>
        </div>
        <div class="s1">
          <p class="title">Socials</p>
          <a class="link fb" href="https://www.facebook.com/rdscorp/" target="_blank"><span
              class="mdi mdi-facebook"></span> Facebook</a>
          <a class="link ig" href="https://www.instagram.com/rdscorpglobal/" target="_blank"><span
              class="mdi mdi-instagram"></span> Instagram</a>
          <a class="link x" href="https://www.x.com/rdscorpglobal/" target="_blank"><span class="mdi mdi-twitter"></span>
            Twitter</a>
          <a class="link ln" href="https://www.linkedin.com/company/rdscorp/" target="_blank"><span
              class="mdi mdi-linkedin"></span> LinkedIn</a>
        </div>
        <div class="s1">
          <p class="title">Terms & Policies</p>
          <a class="link n" href="terms">Terms & Conditions</a>
          <a class="link n" href="privacy">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="d2">
      <p class="title">Copyright © 2024 RDS Corp. All rights reserved</p>
    </div>
  </footer>
</template>
<script>
import axios from 'axios';

export default {
  name: "ContactUs",
  mounted() {
    document.title = "Contact";
    if (document.getElementById(`nav_${window.location.pathname}`)) {
      document
        .getElementById(`nav_${window.location.pathname}`)
        .classList.add("selected");
    }
  },
  data: () => ({
    valid: false,
    name: '',
    companyName: '',
    email: '',
    phone: '',
    heardFrom: '',
    formloading:false,
    message: '',
    agree: false,
    heardFromOptions: ['Referral', 'Social Media', 'Google Search', 'Advertisements', 'Others']
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        const templateParams = {
          service_id: 'service_ozm7rgn',
          template_id: 'template_l09r4oe',
          user_id: 'iyxEoZnBBz4rUMzhT',
          template_params: {
            name: this.name,
            companyName: this.companyName,
            email: this.email,
            phone: this.phone,
            heardFrom: this.heardFrom,
            message: this.message,
            reply_to: 'support@in.rajputanards.com'
          }
        };

        try {
          this.formloading=true;
          const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', templateParams);
          if (response.status === 200) {
            alert('Form Submitted and email sent!');
            this.$refs.form.reset();
          } else {
            alert('Form submission failed. Please try again.');
          }
        } catch (error) {
          alert('There was an error submitting the form: ' + error.message);
        } finally {
          this.formloading=false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
a {
  text-decoration: none;
  color: inherit;
}

.hm1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg, #fff 90%, transparent 100%);
  padding: 20px;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    height: 80px;
    width: 80px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  nav {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    gap: 25px;

    .button {
      background-color: rgb(229, 229, 229);
      height: 40px;
      width: auto;
      border-radius: 1000px;
      font-size: 18px;
      padding-left: 20px;
      padding-right: 20px;
      display: grid;
      place-content: center;
    }

    .button:hover {
      outline: 2px solid #046fdb;
      outline-offset: 2px;
    }

    .button.selected {
      background-color: black;
      color: white;
    }
  }
}

.csec1 {
  padding-left: 150px;
  padding-right: 150px;
  margin-top: 250px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: fb;
    font-size: 55px;
  }

  .subtitle {
    font-family: fm;
    font-size: 35px;
    color: gray;
  }

  .b1 {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #0071e3;
    color: white;
    border-radius: 100px;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 150px;
    cursor: pointer;
  }

  .b1:hover {
    opacity: 0.8;
  }

}
.vbtn{
  border-radius: 1000px;
  text-transform: capitalize;
  font-size: 17px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 0;
}
.fm1 {
  width: 100%;
  padding-left: 150px;
  padding-right: 150px;
  background-color: rgb(0, 0, 0);
  padding-top: 20px;

  .d1 {
    display: flex;
    justify-content: space-between;

    .sd1 {
      display: flex;
      flex-direction: column;

      .logo {
        width: 250px;
        height: 250px;
      }
    }

    .sd2 {
      display: flex;
      padding-top: 30px;
      gap: 50px;

      .s1 {
        display: flex;
        flex-direction: column;
        color: #fff;
        gap: 5px;

        .title {
          color: rgb(142, 142, 142);
          font-size: 17.5px;
        }

        .link {
          font-size: 20px;
          cursor: pointer;
        }

        .link.fb:hover {
          color: #1877f2;
        }

        .link.ig:hover {
          color: #fe0b81;
        }

        .link.x:hover {
          color: #1da1f2;
        }

        .link.ln:hover {
          color: #0077b5;
        }

        .link.n:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .d2 {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;

    .title {
      width: 100%;
      text-align: center;
      font-family: fl;
      color: white;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .hm1 {
    padding: 30px;
    height: 150px;

    .logo {
      width: 70px;
      height: 70px;
    }

    nav {
      display: none;
    }
  }

  .csec1 {
    padding-left: 30px;
    padding-right: 30px;
    .title {
      font-family: fm;
      font-size: 50px;
    }
  
    .subtitle {
      font-size: 30px;
      color: gray;
    }
  
    .d1 {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .t1 {
        font-family: fm;
        font-size: 22.5px;
      }
  
      .b1 {
        font-size: 20px;
      }
  
      .bpt {
        font-size: 20px;
        font-family: fm;
      }
  
      ul {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  
  }

  .fm1 {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(0, 0, 0);
    padding-top: 20px;

    .d1 {
      display: flex;
      flex-direction: column;

      .sd1 {
        display: flex;
        flex-direction: column;

        .logo {
          width: 200px;
          height: 200px;
        }
      }

      .sd2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        gap: 50px;

        .s1 {
          display: flex;
          flex-direction: column;
          color: #fff;
          gap: 5px;

          .title {
            color: rgb(142, 142, 142);
            font-size: 17.5px;
          }

          .link {
            font-size: 20px;
            cursor: pointer;
          }

          .link.fb:hover {
            color: #1877f2;
          }

          .link.ig:hover {
            color: #fe0b81;
          }

          .link.x:hover {
            color: #1DA1F2;
          }

          .link.ln:hover {
            color: #0077b5;
          }

          .link.n:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .d2 {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      margin-top: 20px;
      padding-bottom: 20px;

      .title {
        width: 100%;
        text-align: center;
        font-family: fl;
        color: white;
      }
    }
  }
}

</style>
  